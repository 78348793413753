import { _fireStore } from '@/helpers/firebase'
import { differenceInMilliseconds } from 'date-fns'
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { put } from 'redux-saga/effects'
import { clearActiveChatAction } from '../../actions'
import { cancelChatAction } from '../../handles/cancelChatAction'
import { Chat } from '../../types'
import { onEndServiceHook } from '../closeChatSaga/http/onEndServiceHook'
import { User } from '@/models/user.model'
import { updateUserAction } from '@/redux/user/handles'

export function* cancelChatSaga(action: ReturnType<typeof cancelChatAction.request>) {
  const { chat, userAuthenticatedId } = action.payload
  try {
    const updatedData: Partial<Chat> = {
      status: 'open',
      operators: [],
      operatorsId: [],
      category: null,
      startDate: chat.startDate,
      endDate: chat.endDate
    }
    updatedData.endDate = new Date().getTime()
    updatedData.duration = differenceInMilliseconds(new Date(updatedData.endDate), new Date(updatedData.startDate))
    const user: User = yield findUserById(userAuthenticatedId)
    const updatedAttendFor = user?.attendFor?.filter(campaignId => campaignId !== chat.campaignId) || []

    yield updateChat(chat.id, updatedData)
    yield put(cancelChatAction.success())
    yield put(updateUserAction.request({ userId: user.id, userUpdateData: { attendFor: updatedAttendFor } }))
    yield put(clearActiveChatAction())
    yield onEndServiceHook({ ...chat, ...updatedData })
  } catch (error) {
    console.error('Error when ending chat:', error)
    yield put(cancelChatAction.failure(error))
  }
}

async function findUserById(userId: string) {
  const userDocRef = doc(collection(_fireStore, 'users'), userId)
  const userDoc = await getDoc(userDocRef)
  if (!userDoc.exists()) return null
  return userDoc.data() as User
}

async function updateChat(chatId: string, updatedData: Partial<Chat>) {
  const collectionName = `chats`
  const chatDocRef = doc(collection(_fireStore, collectionName), chatId)
  await updateDoc(chatDocRef, updatedData)
}
