import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Request, Success, Failure } from './types'
import { StickerState } from '../../types'

export const getStickersAction = createAsyncAction(
  '@sticker/GET_STICKERS_REQUEST',
  '@sticker/GET_STICKERS_SUCCESS',
  '@sticker/GET_STICKERS_FAILURE'
)<Request, Success, Failure>()

const request = (state: StickerState): StickerState => ({
  ...state,
  isLoadingGetStickers: true
})

const success = (state: StickerState, action: ActionType<typeof getStickersAction.success>): StickerState => ({
  ...state,
  stickers: [...state.stickers, ...action.payload],
  isLoadingGetStickers: false,
  isSuccessOnLoadingGetStickers: true
})

const failure = (state: StickerState): StickerState => ({
  ...state,
  isLoadingGetStickers: false,
  isErrorOnGetStickers: true
})

export const getStickersHandle = { asyncAction: getStickersAction, request, success, failure }
