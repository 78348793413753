import { _fireStore } from '@/helpers/firebase'
import { collection, doc, getDocs, runTransaction, writeBatch } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'
import { buildUnreadMessagesQuery } from './helpers'
import { readMessagesFromChatAction } from '../../handles'

export function* readMessagesFromChatSaga(action: ReturnType<typeof readMessagesFromChatAction.request>) {
  const { chat, ownerId } = action.payload
  try {
    yield call(clearUnreadMessagesCoutFromChat, chat.id)
    yield call(readMessagesFromChat, chat.id, ownerId)
  } catch (error) {
    console.log({ readMessagesFromChatSaga: error })
    console.log({ chat })
    yield put(readMessagesFromChatAction.failure(chat))
  }
}

async function clearUnreadMessagesCoutFromChat(chatId: string) {
  const collectionName = `chats`
  const chatRef = doc(collection(_fireStore, collectionName), chatId)
  await runTransaction(_fireStore, async transaction => {
    const chatSnapshot = await transaction.get(chatRef)
    if (chatSnapshot.exists()) {
      transaction.update(chatRef, {
        unreadMessagesCount: 0,
        updatedAt: new Date().getTime()
      })
    }
  })
}

async function readMessagesFromChat(chatId: string, ownerId: string) {
  try {
    const messagesQuerySnapShot = await getDocs(buildUnreadMessagesQuery(chatId, ownerId))
    if (messagesQuerySnapShot.empty) return
    const batch = writeBatch(_fireStore)
    messagesQuerySnapShot.docs.map(async doc => {
      batch.update(doc.ref, { isRead: true })
    })
    await batch.commit()
  } catch (error) {
    throw new Error(error)
  }
}
