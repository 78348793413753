import { _fireBaseStorage, _fireStore } from '@/helpers/firebase'
import { isEmpty } from '@/helpers/string.helpers'
import { updateActiveChatAction } from '@/redux/chat/actions'
import { Chat, Operator } from '@/redux/chat/types'
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { put } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { CreateMessageRequestParam, createMessageAsync } from '../../actions'
import { Message, MessageResponse } from '../../types'
import { generateSixDigitTicketNumber } from '../../utils'
import { sendMessage } from './http/message'
import { SendMessageResponse } from './http/message/types'
import { onStartServiceHook } from './http/onStartServiceHook'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { User } from '@/models/user.model'
import { updateUserAction } from '@/redux/user/handles'

export function* createMessageSaga(action: ReturnType<typeof createMessageAsync.request>) {
  const { message, ownerId, campanhaId, token } = action.payload
  try {
    const newMessage = yield createNewMessage({ message, ownerId })
    const sendMessageResult: SendMessageResponse = yield sendMessageToZApiMultiService(newMessage, campanhaId, token)
    newMessage.messageId = sendMessageResult.messageId
    yield uploadMessageToFirestore(newMessage)
    yield updateChatWithNewMessage(newMessage)
    yield put(createMessageAsync.success())
  } catch (error) {
    yield put(createMessageAsync.failure(error))
  }
}

export async function sendMessageToZApiMultiService(newMessage: Message, campaignId: string, token: string) {
  if (newMessage.messageText.trim()) {
    return await sendMessage({
      campaignId,
      token,
      body: {
        phone: newMessage.receiverNumber.toString(),
        message: newMessage.messageText
      }
    })
  }

  const [type, ext] = newMessage.file.type.split('/')
  switch (type) {
    case 'image':
      if (ext === 'webp') {
        return await sendMessage({
          campaignId,
          token,
          documentType: 'send-sticker',
          body: {
            phone: newMessage.receiverNumber.toString(),
            sticker: newMessage.file.url
          },
          extensionFile: 'image/webp'
        })
      } else {
        return await sendMessage({
          campaignId,
          token,
          documentType: 'send-image',
          body: {
            phone: newMessage.receiverNumber.toString(),
            image: newMessage.file.url
          }
        })
      }
    case 'audio':
      return await sendMessage({
        campaignId,
        token,
        documentType: 'send-audio',
        body: {
          phone: newMessage.receiverNumber.toString(),
          audio: newMessage.file.url
        }
      })

    case 'video':
      return await sendMessage({
        campaignId,
        token,
        documentType: 'send-video',
        body: {
          phone: newMessage.receiverNumber.toString(),
          video: newMessage.file.url
        }
      })

    default:
      return await sendMessage({
        campaignId,
        token,
        documentType: 'send-document',
        extensionFile: newMessage.file.type.split('/')[1],
        body: {
          phone: newMessage.receiverNumber.toString(),
          document: newMessage.file.url
        }
      })
  }
}

async function createNewMessage({ message, ownerId }: Partial<CreateMessageRequestParam>): Promise<Message> {
  let file: Message['file'] = {} as Message['file']
  let filePath: string
  if (message.file) {
    filePath = `storage_to_${ownerId}/data/media/images`
    const fileUrl = await uploadFileToStorage(message.file, filePath)

    file = {
      name: message.file.name,
      type: message.file.type,
      size: message.file.size,
      url: fileUrl
    }
  }

  return {
    ...message,
    messageId: '',
    isRead: false,
    isSend: true,
    file,
    createdAt: new Date().getTime()
  }
}

async function uploadFileToStorage(file: File, filePath: string) {
  const fileExt = file.name.split('.')[1]
  const buildedFileName = `${uuid()}.${fileExt}`
  const fileName = filePath + buildedFileName
  const storageRef = ref(_fireBaseStorage, fileName)
  try {
    const snapshot = await uploadBytes(storageRef, file)
    const downloadURL = await getDownloadURL(snapshot.ref)
    return downloadURL
  } catch (error) {
    throw new Error(error)
  }
}

function* uploadMessageToFirestore(message: Message) {
  const collectionName = `messages`
  const newMessageDoc = doc(collection(_fireStore, collectionName), message.messageId)
  yield setDoc(newMessageDoc, message)
}

// TODO: Fix this
export type MessageRequest = {
  chatId?: string
  operator?: { id: string; name: string }
} & MessageResponse

function* updateChatWithNewMessage(newMessage: MessageRequest) {
  const collectionName = `chats`
  const chatDoc = doc(collection(_fireStore, collectionName), newMessage.chatId)
  const chatSnapshot = yield getDoc(chatDoc)
  if (chatSnapshot.exists()) {
    const chatData: Chat = chatSnapshot.data()
    const ticketNumber = generateSixDigitTicketNumber()
    const operatorExists = chatData.operatorsId.some(operatorId => operatorId === newMessage?.operator?.id)
    const updatedChatData: Chat = {
      ...chatData,
      startDate: chatData.startDate ? chatData.startDate : new Date().getTime(),
      lastMessage: newMessage,
      status: chatData.status === 'open' ? 'in_progress' : chatData.status,
      ticket: chatData.ticket ? chatData.ticket : ticketNumber,
      operators: updateOperator(chatData.operators, newMessage?.operator)
    }
    updatedChatData.operatorsId = updateOperatorsId(chatData.operatorsId, newMessage?.operator?.id)
    yield updateDoc(chatDoc, updatedChatData)
    yield put(updateActiveChatAction(updatedChatData))
    yield handleChangesWhenChatIsOpenAtFirst(chatData, newMessage)
    if (!operatorExists) {
      yield onStartServiceHook({ currentChat: updatedChatData })
    }
  }
}

function* handleChangesWhenChatIsOpenAtFirst(chat: Chat, newMessage: MessageRequest) {
  if (chat.status === 'open') {
    yield setChatControlChange(chat)
    const user: User = yield findUserById(newMessage.operator.id)
    const hasAttendCampaign = user?.attendFor?.find(campaignId => campaignId === chat.campaignId)
    if (hasAttendCampaign) return
    const updatedAttendFor = user?.attendFor?.length ? [...user.attendFor, chat.campaignId] : [chat.campaignId]
    yield put(updateUserAction.request({ userId: user.id, userUpdateData: { attendFor: updatedAttendFor } }))
  }
}

async function findUserById(userId: string) {
  const userDocRef = doc(collection(_fireStore, 'users'), userId)
  const userDoc = await getDoc(userDocRef)
  if (!userDoc.exists()) return null
  return userDoc.data() as User
}

function updateOperatorsId(operatorsId: string[] = [], newOperatorId: string): string[] {
  if (!newOperatorId) return operatorsId
  if (!operatorsId) return [newOperatorId]
  return operatorsId?.includes(newOperatorId) ? operatorsId : [newOperatorId, ...operatorsId]
}

function updateOperator(operators: Operator[] = [], operator: Operator): Operator[] {
  if (isEmpty(operator)) return []
  if (!operators?.length) return [operator]
  const operatorExists = operators?.find(_operator => _operator.id === operator.id)
  return operatorExists ? operators : [operator, ...operators]
}

async function setChatControlChange(chat: Chat) {
  const _collectionName = `chatControlChanges`
  const newDoc = doc(collection(_fireStore, _collectionName), chat.campaignId)
  const chatControlDoc = await getDoc(newDoc)
  if (chatControlDoc.exists()) {
    const isChange = chatControlDoc.data()?.isChanged
    await updateDoc(chatControlDoc.ref, { isChanged: !isChange })
  } else {
    await setDoc(newDoc, { campaignId: chat.campaignId, ownerId: chat.ownerId, isChanged: true })
  }
}
