import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Request, Success, Failure } from './types'
import { ChatState } from '../../types'
import { updateChatInChatsState } from '../helpers'

export const readMessagesFromChatAction = createAsyncAction(
  '@chat/READ_MESSAGES_FROM_CHAT_REQUEST',
  '@chat/READ_MESSAGES_FROM_CHAT_SUCCESS',
  '@chat/READ_MESSAGES_FROM_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, action: ActionType<typeof readMessagesFromChatAction.request>): ChatState => ({
  ...state,
  isLoadingReadMessagesFromChat: true,
  isErrorOnReadMessagesFromChat: false
})

// TODO: remove this because is unused
const success = (state: ChatState, action: ActionType<typeof readMessagesFromChatAction.success>): ChatState => ({
  ...state,
  isLoadingReadMessagesFromChat: false,
  isErrorOnReadMessagesFromChat: false,
  activeChat: action.payload,
  chats: updateChatInChatsState(action.payload, state.chats)
})

const failure = (state: ChatState, action: ActionType<typeof readMessagesFromChatAction.failure>): ChatState => ({
  ...state,
  isLoadingReadMessagesFromChat: false,
  isErrorOnReadMessagesFromChat: true,
  chats: updateChatInChatsState(action.payload, state.chats)
})

export const readMessagesFromChatHandle = { asyncAction: readMessagesFromChatAction, request, success, failure }
