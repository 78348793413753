import { _fireStore } from '@/helpers/firebase'
import { toast } from '@/helpers/toast/toast.helper'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'
import { selectDepartmentAction } from '../../handles'
import { delay, getCurrentTimeInMinutes, getTodaySchedule, timeToMinutes } from './helpers'
import { ScheduleByDepartmentModel } from '@/models/schedule'

export function* setDepartmentSaga(action: ReturnType<typeof selectDepartmentAction.request>) {
  try {
    const { selectedDepartment, userAuthenticated } = action.payload
    if (userAuthenticated?.role === 'MASTER')
      return yield put(selectDepartmentAction.success({ departmentCheck: true, selectedDepartment }))

    const scheduleByDepartment: ScheduleByDepartmentModel = yield call(
      findScheduleByDepartment,
      selectedDepartment?.companyId,
      selectedDepartment?.id
    )

    if (!scheduleByDepartment?.id || !scheduleByDepartment?.isLoginBeforeHour)
      return yield put(selectDepartmentAction.success({ departmentCheck: true, selectedDepartment }))

    const todaySchedule = getTodaySchedule(scheduleByDepartment.schedule)

    if (!todaySchedule) throw new Error('schedule_not_found')

    const currentTimeInMinutes = getCurrentTimeInMinutes()
    const startTimeInMinutes = timeToMinutes(todaySchedule.startTime)

    if (startTimeInMinutes > currentTimeInMinutes) throw new Error('login_before_hour')

    yield toast({
      type: 'success',
      message: `Departamento ${selectedDepartment?.name}`
    })

    yield put(selectDepartmentAction.success({ departmentCheck: true, selectedDepartment }))
  } catch (error) {
    console.log('verifyDepartment', error)
    if (error.message === 'schedule_not_found') {
      yield toast({
        type: 'error',
        message: `O Departamento ${action.payload.selectedDepartment?.name} não tem horário de atendimento hoje`
      })
      yield put(
        selectDepartmentAction.failure({
          isError: true,
          departmentCheck: null,
          selectedDepartment: action.payload?.returnOldDepartment ? null : action.payload?.selectedDepartment
        })
      )
      return
    }

    if (error.message === 'login_before_hour') {
      yield toast({
        type: 'error',
        message: `O Departamento ${action.payload.selectedDepartment?.name} está fora do horário de atendimento`
      })
      yield call(delay, 500)
      yield put(
        selectDepartmentAction.failure({
          isError: true,
          departmentCheck: null,
          selectedDepartment: action.payload?.returnOldDepartment ? null : action.payload?.selectedDepartment
        })
      )
      return
    }

    yield toast({
      type: 'error',
      message: `Erro interno, por favor tente novamente.`
    })
    yield put(
      selectDepartmentAction.failure({
        isError: true,
        departmentCheck: null,
        selectedDepartment: action.payload?.returnOldDepartment ? null : action.payload?.selectedDepartment
      })
    )
  }
}

async function findScheduleByDepartment(companyId: string, department: string) {
  try {
    if (!companyId) throw new Error('Company ID is required')

    const _query = query(
      collection(_fireStore, 'schedule-by-department-configs'),
      where('companyId', '==', companyId),
      where('departmentId', '==', department)
    )

    const admSchedule = await getDocs(_query)

    if (admSchedule.empty) return null

    const docs = admSchedule.docs.map(doc => doc.data())
    const schedule = docs.map(data => data)[0] as ScheduleByDepartmentModel

    return schedule
  } catch (error) {
    console.log('consultScheduleAndPermission', error)
    throw error
  }
}
