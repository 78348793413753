import { MessageResponse } from '@/redux/message/types'
import { Chat } from '../types'
import { isEmpty } from '@/helpers/string.helpers'

export function addUniqueMessages(existingMessages: MessageResponse[], newMessages: MessageResponse[]) {
  if (!existingMessages?.length) {
    existingMessages = []
  }
  const updatedMessages = [...existingMessages, ...newMessages]
  return updatedMessages
}

export function updateChatInChatsState(currentChat: Chat, chats: Chat[]) {
  return chats.map(chat => (chat.id === currentChat.id ? currentChat : chat))
}

export function addChatInChatsState(existingChats: Chat[], chatToAdd: Chat): Chat[] {
  if (isEmpty(chatToAdd)) {
    return existingChats
  }
  const existingChatIndex = existingChats.findIndex(chat => chat.id === chatToAdd.id)
  if (existingChatIndex !== -1) {
    const updatedChats = [...existingChats]
    updatedChats[existingChatIndex] = chatToAdd
    return updatedChats
  }
  return [chatToAdd, ...existingChats]
}
