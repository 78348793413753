import { put } from 'redux-saga/effects'
import { deleteMessageAsync } from '../../actions'
import { deleteDoc, doc } from 'firebase/firestore'
import { MessageResponse } from '../../types'
import { _fireStore } from '@/helpers/firebase'
import { deleteMessageFromChatAction, setActiveChatMessageAction } from '@/redux/chat/actions'

export function* deleteMessageSaga(action: ReturnType<typeof deleteMessageAsync.request>) {
  const { message: messageToDelete } = action.payload
  try {
    yield put(deleteMessageFromChatAction(messageToDelete))
    yield deleteMessageFromFirestore(messageToDelete)
  } catch (error) {
    yield handleDeleteMessageError(messageToDelete, error)
  }
}

export function* deleteMessageFromFirestore(message: MessageResponse) {
  const collectionName = `messages`
  const messageRef = doc(_fireStore, collectionName, message.messageId)
  yield deleteDoc(messageRef)
}

export function* handleDeleteMessageError(message: MessageResponse, error: Error) {
  yield put(setActiveChatMessageAction(message))
  yield put(deleteMessageAsync.failure(error))
}
