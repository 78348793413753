import { _fireStore } from '@/helpers/firebase'
import { sendMessageToZApiMultiService } from '@/redux/message/sagas/createMessageSaga'
import { SendMessageResponse } from '@/redux/message/sagas/createMessageSaga/http/message/types'
import { Message } from '@/redux/message/types'
import { differenceInMilliseconds } from 'date-fns'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'
import { clearActiveChatAction } from '../../actions'
import { transferChatAction } from '../../handles/transferChatAction'
import { Chat } from '../../types'
import { CreateNewMessageDTO, HandleCustomMessageDTO } from './types'

export function* transferChatSaga(action: ReturnType<typeof transferChatAction.request>) {
  try {
    const { currentChat, currentOperator, newDepartment, currentCampaign } = action.payload

    const sendedMessage = yield handleCustomMessage({
      campaign: currentCampaign,
      currentChat,
      currentOperator,
      newDepartment
    })

    const dateNow = new Date().getTime()
    const updatedChatData: Chat = {
      ...currentChat,
      lastMessage: sendedMessage,
      department: newDepartment,
      departmentId: newDepartment.id,
      status: 'open',
      operators: [],
      operatorsId: [],
      endDate: dateNow,
      duration: differenceInMilliseconds(new Date(dateNow), new Date(currentChat.startDate)),
      operatorsHistories: currentChat?.operatorsHistories?.length
        ? [
            ...currentChat?.operatorsHistories,
            { id: currentOperator.id, name: currentOperator.name, attend_at: dateNow }
          ]
        : [{ id: currentOperator.id, name: currentOperator.name, attend_at: dateNow }],
      updatedAt: dateNow
    }

    yield call(updateChat, currentChat.id, updatedChatData)
    yield put(transferChatAction.success({ transferedChat: updatedChatData }))
    yield put(clearActiveChatAction())
  } catch (error) {
    yield put(transferChatAction.failure())
  }
}

async function updateChat(chatId: string, updatedData: Partial<Chat>) {
  const chatDocRef = doc(collection(_fireStore, 'chats'), chatId)
  await updateDoc(chatDocRef, updatedData)
}

async function handleCustomMessage({ campaign, currentChat, currentOperator, newDepartment }: HandleCustomMessageDTO) {
  const newMessage = await createNewMessage({
    campaign,
    currentChat,
    newDepartment,
    senderId: currentOperator.id
  })
  const sendMessageResult: SendMessageResponse = await sendMessageToZApiMultiService(
    newMessage,
    campaign.id,
    campaign.token
  )
  newMessage.messageId = sendMessageResult.messageId

  await uploadMessageToFirestore(newMessage)
  return newMessage
}

async function createNewMessage({
  campaign,
  currentChat,
  newDepartment,
  senderId
}: CreateNewMessageDTO): Promise<Message> {
  return {
    campanhaId: campaign.id,
    instanceId: campaign.instanceId,
    ownerId: campaign.ownerId,
    file: null,
    messageText: `Atendimento sendo transferido para o departamento *${newDepartment.name}*, aguarde um momento.`,
    senderNumber: currentChat.receiverNumber,
    senderId,
    receiverNumber: currentChat.requesterNumber,
    chatId: currentChat.id,
    messageId: '',
    isRead: false,
    isSend: true,
    createdAt: new Date().getTime()
  } as Message
}

async function uploadMessageToFirestore(message: Message) {
  const collectionName = `messages`
  const newMessageDoc = doc(collection(_fireStore, collectionName), message.messageId)
  await setDoc(newMessageDoc, message)
}
